import fetchWithAuth from './fetchWithAuth';

const baseUrl = 'https://constraints.products.cimpress.io';
const route = 'api/v1/constraints';

export const getConstraint = ({ constraintId, accessToken }) =>
  fetchWithAuth({
    baseUrl,
    route: route + `/${constraintId}`,
    giveSimpleResponse: true,
    accessToken,
  });

// Note there's no put route, you need to create new constraints every time.
export const createConstraint = ({ constraint, accessToken }) =>
  fetchWithAuth({
    baseUrl,
    method: 'POST',
    route,
    body: constraint,
    giveSimpleResponse: true,
    accessToken,
  });
