import React from 'react';

import Checkbox from '@cimpress/react-components/lib/Checkbox';
import { rowStyle, checkBoxIdStyle, noMargin } from './css';

const Item = ({ product, isSelected = false, setProductsCheck }) => {
  const toggleCheck = () => {
    setProductsCheck([product], !isSelected);
  };

  return (
    <div className={rowStyle}>
      <div className={checkBoxIdStyle}>
        <Checkbox style={{ margin: '0px' }} checked={isSelected} onChange={toggleCheck} />
        <p className={noMargin}>{product.name}</p>
      </div>
      <p className={noMargin}>{product.productId}</p>
    </div>
  );
};

export default Item;
