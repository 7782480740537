import { defineMessages } from 'react-intl';

export default defineMessages({
  rangeExactly: {
    id: 'rangeTranslator.rangeExactly',
    defaultMessage: 'Exactly {minimum}',
  },
  rangeMaximumWithIncrement: {
    id: 'rangeTranslator.rangeMaximumWithIncrement',
    defaultMessage: '{minimum} to {maximum} increment {increment}',
  },
  rangeMaximumNoIncrement: {
    id: 'rangeTranslator.rangeMaximumNoIncrement',
    defaultMessage: '{minimum} to {maximum} no increment',
  },
  rangeNoMaximumWithIncrement: {
    id: 'rangeTranslator.rangeNoMaximumWithIncrement',
    defaultMessage: '{minimum} to infinity increment {increment}',
  },
  rangeNoMaximumNoIncrement: {
    id: 'rangeTranslator.rangeNoMaximumNoIncrement',
    defaultMessage: '{minimum} to infinity no increment',
  },
  moreCount: {
    id: 'listToStringFormatter.moreCount',
    defaultMessage: '{count} more',
  },
});
