export const productTypeConsts = {
  V1_FULFILLMENT: 'V1_FULFILLMENT',
  V2_FULFILLMENT: 'V2_FULFILLMENT',
  STANDARDS: 'STANDARDS',
  MERCHANT: 'MERCHANT',
  ADOPTION: 'ADOPTION',
};

export const aggregationTypes = { SUPERSET: 'SUPERSET', INTERSECTION: 'INTERSECTION' };

export const existingSelectedPlink = {
  LINKID: 'linkId',
  HREF: 'href',
  RELATEDRESOURCE: 'relatedResourceHref',
};
