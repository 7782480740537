import { buildOptions, checkResponse } from './serviceHelpers';

const fetchWithAuth = async ({
  link,
  baseUrl,
  method = 'GET',
  route,
  body,
  additionalHeaders = {},
  giveSimpleResponse = true,
  accessToken,
}) => {
  if (!link && (!baseUrl || !route))
    throw new Error(`Url, baseUrl or route not provided when making a ${method} request`);

  const options = buildOptions(method, body, additionalHeaders, accessToken);
  let fullUrl = link
    ? link
    : baseUrl.lastIndexOf('/') !== baseUrl.length - 1
    ? `${baseUrl}/${route}`
    : `${baseUrl.substring(0, baseUrl.length - 1)}${route}`;

  try {
    const rawResponse = await fetch(fullUrl, options);

    const parsedResponse = await checkResponse(rawResponse, `${method} ${fullUrl}`, giveSimpleResponse);

    return parsedResponse;
  } catch (error) {
    if (process.env.NODE_ENV !== 'test') {
      // TODO find out the name of other envs besides 'production'
      console.error(error);
    }
    throw error;
  }
};

export default fetchWithAuth;
