import { defineMessages } from 'react-intl';

export default defineMessages({
  conditionSetName: {
    id: 'conditionSetEditor.conditionSetName',
    defaultMessage: 'Condition Set Name',
  },
  mustProvideConditionSetName: {
    id: 'conditionSetEditor.mustProvideConditionSetName',
    defaultMessage: 'Must provide Condition Set name',
  },
  existingConditionSet: {
    id: 'conditionSetEditor.existingConditionSet',
    defaultMessage: 'There is already a Condition Set with the name {name}.  Names must be unique.',
  },
  noConditionsAddToStart: {
    id: 'conditionSetEditView.noConditionsAddToStart',
    defaultMessage: 'No conditions for this condition set. Add one to get started.',
  },
  addCondition: {
    id: 'conditionSetEditView.addCondition',
    defaultMessage: 'Add condition',
  },
  attributeLabel: {
    id: 'conditionEditor.attributeLabel',
    defaultMessage: 'Attribute',
  },
  cancel: {
    id: 'conditionSetWrapper.cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'conditionSetWrapper.save',
    defaultMessage: 'Save',
  },
  yes: {
    id: 'conditionSetWrapper.yes',
    defaultMessage: 'Yes',
  },
  modalTitle: {
    id: 'conditionSetWrapper.modalTitle',
    defaultMessage: 'Are you sure you want to save modifications to ({conditionSetName}) condition set?',
  },
  modalBody: {
    id: 'conditionSetWrapper.modalBody',
    defaultMessage: 'This will affect other uses of this condition set elsewhere.',
  },
  conditionErrorStartMessage: {
    id: 'conditionEditor.conditionErrorStartMessage',
    defaultMessage: 'Incomplete condition. Missing: ',
  },
  missingAttribute: {
    id: 'conditionEditor.missingAttribute',
    defaultMessage: 'attribute name',
  },
  missingOperator: {
    id: 'conditionEditor.missingOperator',
    defaultMessage: 'operator',
  },
  missingValues: {
    id: 'conditionEditor.missingValues',
    defaultMessage: 'values',
  },
  conditionErrorEndMessage: {
    id: 'conditionEditor.conditionError',
    defaultMessage: ' Add the missing part to complete the condition.',
  },
  and: {
    id: 'conditionEditor.and',
    defaultMessage: ' and ',
  },
});
