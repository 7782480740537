import React from 'react';
import { cx, css } from 'emotion';

import { ocean, alloy } from '@cimpress/react-components/lib/colors';
import { fivePxMarginRight, fivePxMarginBottom } from './commonStyles';

const xButton = css`
  border: none;
  background-color: transparent;
  line-height: 14px;
  font-weight: 100;
  font-size: 10px;
  color: ${ocean.base};
  margin-right: 8px;
  padding: 0px;

  &:hover {
    color: ${alloy};
  }
`;

export const LeftXTag = ({ label, onRemove, removable = true }) => {
  return (
    <span className={cx('tag', fivePxMarginRight, fivePxMarginBottom)}>
      {removable ? (
        <button className={xButton} onClick={onRemove}>
          x
        </button>
      ) : null}
      {label}
    </span>
  );
};
