import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { cobalt, teal } from '@cimpress/react-components/lib/colors';

/* eslint-disable react/jsx-curly-brace-presence */
export class Unchecked extends PureComponent {
  uniqueId = uuidv4();

  render() {
    const { width, color, backgroundColor } = this.props;
    return (
      <svg name="unchecked" width={width} height={width} viewBox="0 0 24 24">
        <defs>
          <path
            id={this.uniqueId}
            d={`M0,2.38895416 C0,1.06957121 1.07785486,0 2.38895416,0 L21.6110458,0 C22.9304288,0 24,1.07785486 24,2.38895416 L24,
            21.6110458 C24,22.9304288 22.9221451,24 21.6110458,24 L2.38895416,24 C1.06957121,24 0,22.9221451 0,21.6110458 L0,
            2.38895416 Z M2.4,21.6 L21.6,21.6 L21.6,2.4 L2.4,2.4 L2.4,21.6 Z`}
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24" rx="3" stroke="black" strokeWidth="0" fill={backgroundColor} />
          <use fill={color} xlinkHref={`#${this.uniqueId}`} />
        </g>
      </svg>
    );
  }
}

Unchecked.propTypes = {
  width: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

Unchecked.defaultProps = {
  width: '16px',
  color: cobalt.base,
  backgroundColor: 'none',
};

export class Checked extends PureComponent {
  // eslint-disable-line react/no-multi-comp
  uniqueId = uuidv4();

  render() {
    const { width, color, backgroundColor } = this.props;
    return (
      <svg name="checked" width={width} height={width} viewBox="0 0 24 24">
        <defs>
          <path
            id={`${this.uniqueId}-a`}
            d={`M0,1 L0,23 C0,23.5522847 0.44771525,24 1,24 L23,24 C23.5522847,24 24,23.5522847 24,23 L24,
            1 C24,0.44771525 23.5522847,0 23,0 L1,0 C0.44771525,0 0,0.44771525 0,1 Z`}
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24" rx="2" stroke="black" strokeWidth="0" fill={backgroundColor} />
          <mask id={`${this.uniqueId}-b`} fill="#fff">
            <use xlinkHref={`#${this.uniqueId}-a`} />
          </mask>
          <g fill={color} mask={`url(#${this.uniqueId}-b)`}>
            <rect width="24" height="24" />
          </g>
          <g fill="#FFF" mask={`url(#${this.uniqueId}-b)`}>
            <path
              d={`M14.7060415,0.294045226 C14.314157,-0.0980276382 13.711142,-0.0980276382 13.3190691,0.294045226 L5.11817714,
              8.49493719 L1.68102889,5.08797739 C1.28895603,4.69609296 0.685940955,4.69609296 0.294056533,5.08797739 C-0.0980163317,
              5.47986181 -0.0980163317,6.08287688 0.294056533,6.47494975 L4.42459673,10.5754899 C4.60553894,10.7562437 4.87685804,
              10.8769975 5.11817714,10.8769975 C5.35930779,10.8769975 5.63062688,10.7562437 5.8115691,10.5754899 L14.7060415,
              1.71101759 C15.0981143,1.31913317 15.0981143,0.685929648 14.7060415,0.294045226`}
              transform="translate(4.5 6.75)"
            />
          </g>
        </g>
      </svg>
    );
  }
}

Checked.propTypes = {
  width: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

Checked.defaultProps = {
  width: '16px',
  color: teal.base,
  backgroundColor: 'none',
};

export class Indeterminate extends PureComponent {
  // eslint-disable-line react/no-multi-comp
  uniqueId = uuidv4();

  render() {
    const { width, color, backgroundColor } = this.props;
    return (
      <svg name="indeterminate" width={width} height={width} viewBox="0 0 24 24">
        <defs>
          <path
            id={`${this.uniqueId}-a`}
            d={`M0,1 L0,23 C0,23.5522847 0.44771525,24 1,24 L23,24 C23.5522847,24 24,23.5522847 24,23 L24,1 C24,0.44771525 23.5522847,
            0 23,0 L1,0 C0.44771525,0 0,0.44771525 0,1 Z M16.8,12.9 C17.4627417,12.9 18,12.3627417 18,11.7 C18,11.0372583 17.4627417,
            10.5 16.8,10.5 L7.2,10.5 C6.5372583,10.5 6,11.0372583 6,11.7 C6,12.3627417 6.5372583,12.9 7.2,12.9 L16.8,12.9 Z`}
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24" rx="2" stroke="black" strokeWidth="0" fill={backgroundColor} />
          <mask id={`${this.uniqueId}-b`} fill="#fff">
            <use xlinkHref={`#${this.uniqueId}-a`} />
          </mask>
          <g fill={color} mask={`url(#${this.uniqueId}-b)`}>
            <rect width="24" height="24" />
          </g>
        </g>
      </svg>
    );
  }
}

Indeterminate.propTypes = {
  width: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

Indeterminate.defaultProps = {
  width: '16px',
  color: teal.base,
  backgroundColor: 'none',
};
