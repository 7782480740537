export const searchBarTypes = {
  KEYWORD_SEARCH: 'KEYWORD_SEARCH',
  ID_SEARCH: 'ID_SEARCH',
};

export const productTypes = {
  V2_FULFILLMENT: 'V2_FULFILLMENT',
  V1_FULFILLMENT: 'V1_FULFILLMENT',
  STANDARD: 'STANDARD',
  ADOPTION: 'ADOPTION',
  MERCHANT: 'MERCHANT',
};

export const errorTypes = {
  PRODUCT_IS_RETIRED: 'PRODUCT_IS_RETIRED',
  PRODUCT_IS_FAMILY: 'PRODUCT_IS_FAMILY',
  PRODUCT_IS_DRAFT: 'PRODUCT_IS_DRAFT',
  PRODUCT_DOESNT_EXIST: 'PRODUCT_DOESNT_EXIST',
  PRODUCT_IS_ADOPTION: 'PRODUCT_IS_ADOPTION',
  NO_ADOPTIONS: 'NO_ADOPTIONS',
  API_ERROR: 'API_ERROR',
  NO_V2_SEARCH: 'NO_V2_SEARCH',
  NO_V1_SEARCH: 'NO_V1_SEARCH',
  NO_STANDARD_SEARCH: 'NO_STANDARD_SEARCH',
  NO_MERCHANT_SEARCH: 'NO_MERCHANT_SEARCH',
};

export const queryOperatorTypes = {
  AND: 'AND',
  OR: 'OR',
};

export const linkCreationErrorTypes = {
  GROUP_CREATION: 'Product Group',
  CONDITION_CREATION: 'Constraints',
  PLINK_CREATION: 'Product Link',
};
