import { useIntl } from 'react-intl';
import messages from '../messages';
import isEmpty from 'lodash/isEmpty';

const SelectedProductMessage = ({ tempSelectedCatalog, selectedProductCount, defaultMessage }) => {
  const { formatMessage } = useIntl();

  if (selectedProductCount === 0 && isEmpty(tempSelectedCatalog)) {
    return defaultMessage;
  }

  if (isEmpty(tempSelectedCatalog)) {
    if (selectedProductCount > 1) {
      return formatMessage(messages.selectedProductCountSingular, { count: selectedProductCount });
    } else {
      return formatMessage(messages.selectedProductCountPlural, { count: selectedProductCount });
    }
  }

  if (selectedProductCount === 0) {
    return formatMessage(messages.selectedCatalog);
  }

  if (selectedProductCount === 1) {
    return formatMessage(messages.selectedCatalogPlusSingleProduct);
  }

  return formatMessage(messages.selectedCatalogPlusProducts, { count: selectedProductCount });
};

export default SelectedProductMessage;
