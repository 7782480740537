import { buildOptions, checkResponse } from './serviceHelpers';

export const followCategoryLink = async (href, accessToken) => {
  if (href.includes('https://catalogschema.products.cimpress.io/v2/categories/')) {
    return await fetchWithAuthFullURL({
      fullUrl: href,
      accessToken,
    });
  }
  return Promise.reject('Unrecognized link');
};

const fetchWithAuthFullURL = async ({
  fullUrl,
  method = 'GET',
  body,
  additionalHeaders = {},
  giveSimpleResponse = true,
  accessToken,
}) => {
  if (!fullUrl) throw new Error(`FullUrl not provided when making a ${method} request`);

  const options = buildOptions(method, body, additionalHeaders, accessToken);

  try {
    const rawResponse = await fetch(fullUrl, options);

    const parsedResponse = await checkResponse(rawResponse, `${method} ${fullUrl}`, giveSimpleResponse);

    return parsedResponse;
  } catch (error) {
    if (process.env.NODE_ENV !== 'test') {
      // TODO find out the name of other envs besides 'production'
      console.error(error);
    }
    throw error;
  }
};
