import fetchWithAuth from './fetchWithAuth';

const baseUrl = 'https://standard-definition.products.cimpress.io';

export const getStandardInfo = async (id, accessToken) => {
  return await fetchWithAuth({
    baseUrl,
    route: `v1/standards/${id}`,
    accessToken,
  });
};

export const getStandardAdoptions = async (associatedBusinessId, standardId, accessToken) => {
  return await fetchWithAuth({
    baseUrl,
    route: `v1/standards:adoptions?productId=${standardId}&account=${associatedBusinessId}&status=active`,
    accessToken,
  });
};

export const getAdoptionsUnderStandard = async (standardId, accountId, accessToken) => {
  let route = `v1/standards:adoptions?productId=${standardId}&account=${accountId}&status=active&limit=1000`;

  try {
    const adoptions = await fetchWithAuth({ baseUrl, route, accessToken });
    if (adoptions._embedded && adoptions._embedded.item) {
      const adoptionsToReturn = adoptions._embedded.item.map((x) => {
        let currentIndex = 0;
        x.versions.forEach((version, index) => {
          if (version.current) {
            currentIndex = index;
          }
        });
        return {
          productId: x.productId,
          name: x.versions[currentIndex].name,
          version: x.versions[currentIndex].version,
          href: x.versions[currentIndex].href,
          isCurrent: x.versions[currentIndex].current,
        };
      });
      return adoptionsToReturn;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
