import isNil from 'lodash/isNil';

export const mapRangeToText = (range) => {
  if (isNil(range)) {
    return '';
  }

  let maximum;
  if (range.minimum === range.maximum) {
    maximum = '';
  } else if (isNil(range.maximum)) {
    maximum = '-infinity';
  } else {
    maximum = `-${range.maximum}`;
  }

  const minimum = isNil(range.minimum) ? '' : range.minimum;

  return `${minimum}${maximum}${range.increment ? `#${range.increment}` : ''}`;
};

export const mapSingleNumericValueToText = (value) => (isNil(value) ? '' : value);
