import fetchWithAuth from './fetchWithAuth';

export const followLink = async ({ href, accessToken, shouldCache = false }) => {
  let additionalHeaders = {};
  if (!shouldCache) {
    additionalHeaders = { 'Cache-Control': 'no-cache' };
  }
  return await fetchWithAuth({
    link: href,
    accessToken,
    additionalHeaders,
  });
};
