import isNil from 'lodash/isNil';
import { RANGE, VALUE } from '../enums/options';

const MIN_IDX = 1;
const MAX_IDX = 3;
const INCREMENT_IDX = 5;
const RANGE_STRING_REGEX = /^([0-9.]+)(-([0-9.]*|infinity)|[+])?(#([0-9.]+))?$/i;
const INFINITY = 'INFINITY';

export const isRangeTextValid = (rangeText) => {
  const trimmedRangeText = rangeText.trim();

  return trimmedRangeText.length !== 0 && Boolean(trimmedRangeText.match(RANGE_STRING_REGEX));
};

export function parseRange(rangeText) {
  const trimmedRangeText = rangeText.trim();

  const match = trimmedRangeText.match(RANGE_STRING_REGEX);
  if (match) {
    const minimum = match[MIN_IDX];
    const maximum = match[MAX_IDX];
    const increment = match[INCREMENT_IDX];

    // Convert to single numeric value when (no maximum or minimum is the same as maximum) && no increment
    if ((isNil(maximum) || maximum === '' || minimum === maximum) && (isNil(increment) || increment === '')) {
      return { type: VALUE, value: minimum };
    }

    const range = {
      minimum,
    };

    if (!isNil(maximum) && maximum !== '') {
      if (maximum.toUpperCase() !== INFINITY) {
        range.maximum = maximum;
      }
    } else if (isNil(maximum) && isNil(increment)) {
      range.maximum = range.minimum;
    }

    if (!isNil(increment) && increment !== '') {
      range.increment = increment;
    }

    return { type: RANGE, range };
  }

  return null;
}
