import fetchWithAuth from './fetchWithAuth';

const baseUrl = 'https://api.products.cimpress.io';

export const getProductData = async ({ productID, version, accessToken }) => {
  let route = `v1/products/${productID}/versions/${version}`;

  if (version.toLowerCase() === 'current') {
    route = `v1/products/${productID}:current`;
  }
  let product;
  try {
    product = await fetchWithAuth({ baseUrl, route, accessToken });
  } catch (error) {
    route = `v1/products/${productID}:latest`;
    product = await fetchWithAuth({ baseUrl, route, accessToken });
  }
  return product;
};

export const getProductVersions = async ({ productId, accessToken }) => {
  return await fetchWithAuth({
    baseUrl,
    route: `v1/products/${productId}/versions?limit=100&status=active`,
    accessToken,
  });
};

export const getCatalogsByProduct = async ({ productId, accessToken }) => {
  return await fetchWithAuth({
    baseUrl,
    route: `v1/catalogs?productId=${productId}`,
    accessToken,
  });
};

export const getLatest = async ({ productId, accessToken }) =>
  await fetchWithAuth({
    baseUrl,
    route: `v1/products/${productId}:latest`,
    accessToken,
  });

export const getCurrent = async ({ productId, accessToken }) =>
  await fetchWithAuth({
    baseUrl,
    route: `v1/products/${productId}:current`,
    accessToken,
  });

export const getCurrentProductVersion = async ({ productId, accessToken }) => {
  return await getCurrent({ productId, accessToken }).catch(async () => {
    return await getLatest({ productId, accessToken });
  });
};

export const getProductVersion = async ({ productId, version, accessToken }) =>
  await fetchWithAuth({
    baseUrl,
    route: `v1/products/${productId}/versions/${version}`,
    accessToken,
  });

// Fetches existence of product identifier in the system along with current version (if exists)
export const getProduct = async ({ productId, accessToken }) =>
  await fetchWithAuth({
    baseUrl,
    route: `v1/products/${productId}`,
    accessToken,
  });

export const getAttributeModelByProductVersion = async ({ productId, version, accessToken }) =>
  await fetchWithAuth({
    baseUrl,
    route: `v1/products/${productId}/versions/${version}:AsAttributeModel`,
    accessToken,
  });

export const getCatalogsByAccount = async (accountId, accessToken, limit = 200) =>
  await fetchWithAuth({
    baseUrl,
    route: `v1/catalogs?accountId=${accountId}&limit=${limit}`,
    accessToken,
  });

export const getAttributeModelByCurrentProductVersion = async ({ productId, accessToken }) => {
  return await getProduct({ productId, accessToken }).then(async (product) => {
    if (product.current) {
      return await getAttributeModelByProductVersion({ productId, version: product.current, accessToken });
    } else {
      await getLatest({ productId, accessToken }).then(async (latestVersion) => {
        return await getAttributeModelByProductVersion({ productId, version: latestVersion.version, accessToken });
      });
    }
  });
};

export const getCatalogById = async ({ catalogId, accessToken }) =>
  await fetchWithAuth({
    baseUrl,
    route: `v1/catalogs/${catalogId}`,
    accessToken,
  });

export const getProductsInCatalog = async ({ catalogId, accessToken }) =>
  await fetchWithAuth({
    baseUrl,
    route: `v1/catalogs/${catalogId}/products`,
    accessToken,
  });
