import fetchWithAuth from './fetchWithAuth';

const baseUrl = 'https://introduction.products.cimpress.io';

export const getSku = async ({ productId, accessToken }) =>
  await fetchWithAuth({
    baseUrl,
    route: `v3/mcpSkus/${productId}`,
    accessToken,
  });

export const getFamily = async ({ productId, accessToken }) =>
  await fetchWithAuth({
    baseUrl,
    route: `v2/families/${productId}`,
    accessToken,
  });

export const getFamilyBySku = async ({ productId, accessToken }) =>
  await fetchWithAuth({
    baseUrl,
    route: `v2/families?skuReferenceId=${productId}`,
    accessToken,
  });
