import { css } from 'emotion';
import { silver } from '@cimpress/react-components/lib/colors';

export const rowStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 5px 10px;
  &:hover {
    background-color: ${silver};
  }
`;

export const checkBoxIdStyle = css`
  display: flex;
  align-items: center;
`;

export const noMargin = css`
  margin-bottom: 0px !important;
`;
