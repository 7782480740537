import React, { useState } from 'react';

import Checkbox from '@cimpress/react-components/lib/Checkbox';
import { rowStyle, checkBoxIdStyle, noMargin } from './css';

const SelectAllTopItem = ({ searchResults, setProductsCheck }) => {
  const [checked, setChecked] = useState(false);

  const toggleCheck = () => {
    setProductsCheck(searchResults, !checked);
    setChecked(!checked);
  };

  return (
    <div className={rowStyle} key={`dropdown-selectAll`}>
      <div className={checkBoxIdStyle}>
        <Checkbox style={{ margin: '0px' }} checked={checked} onChange={toggleCheck} />
        <p className={noMargin}>Select all {searchResults.length}</p>
      </div>
    </div>
  );
};

export default SelectAllTopItem;
