const types = { NUMERIC: 'numeric', STRING: 'string' };
const numberType = { RANGE: 'range', NUMBERLITERAL: 'numberLiteral' };
const stringTypes = { STRINGLITERAL: 'stringLiteral' };

const conditionTypes = {
  NUMBER: 'number',
  STRING: 'string',
  RANGE: 'range',
  VALUE: 'value',
};

export const convertOptionsToAttributes = (options) => {
  const attributes = {};
  options.forEach((option) => {
    attributes[option.key] = {};
    const attribute = {
      name: option.key,
      values: [],
    };

    if (option.type === types.NUMERIC) {
      attribute.type = conditionTypes.NUMBER;
      option.values.forEach((value) => {
        if (value.type === numberType.RANGE) {
          attribute.values.push({
            type: conditionTypes.RANGE,
            range: {
              minimum: value.range.minimum,
              maximum: value.range.maximum,
              increment: value.range.increment,
            },
          });
        } else if (value.type === numberType.NUMBERLITERAL) {
          attribute.values.push({
            type: conditionTypes.VALUE,
            value: value.numberLiteral,
          });
        } else {
          console.error('unhandled number type: ' + value.type + ' in option: ' + option.key);
        }
      });
      attributes[option.key] = attribute;
    } else if (option.type === types.STRING) {
      attribute.type = conditionTypes.STRING;
      option.values.forEach((value) => {
        if (value.type === stringTypes.STRINGLITERAL) {
          attribute.values.push({
            type: conditionTypes.VALUE,
            value: value.stringLiteral,
          });
        } else {
          // error case
          console.error('unhandled string type: ' + value.type + ' in option: ' + option.key);
        }
      });
      attributes[option.key] = attribute;
    } else {
      console.error('unhandled option type: ' + option.type + ' in option: ' + option.key);
    }
  });

  return attributes;
};
