import messages from './messages';

export const generateListOfItems = (itemList, maxCount, concatinateFunction, formatMessage) => {
  let stringToReturn = '';
  let loopCount = 0;
  for (loopCount = 0; loopCount < itemList.length; loopCount++) {
    if (loopCount === maxCount) {
      break;
    }

    //// Determines which way to split the attribute names
    if (itemList.length !== 1) {
      //// If there are only 2 attributes used in the conditions
      if (loopCount === itemList.length - 1 && itemList.length === 2) {
        stringToReturn = stringToReturn.concat(' & ');
      }
      //// If there are 3 total in the list of attributes used
      //// in the conditions and this is the final loop
      else if (loopCount === maxCount - 1 || loopCount === itemList.length - 1) {
        stringToReturn = stringToReturn.concat(', & ');
      }
      //// If there are 3 total in the list of attribute used
      //// in the conditions and this is not the final loop
      else if (loopCount !== 0) {
        stringToReturn = stringToReturn.concat(', ');
      }
    }

    const itemValue = itemList[loopCount];
    if (loopCount !== maxCount - 1 || maxCount === itemList.length) {
      stringToReturn = concatinateFunction(stringToReturn, itemValue);
    }
  }
  stringToReturn =
    itemList.length - loopCount !== 0
      ? stringToReturn.concat(formatMessage(messages.moreCount, { count: itemList.length - loopCount + 1 }))
      : stringToReturn;

  return stringToReturn;
};
