import { css } from 'emotion';
import { ocean, info, slate } from '@cimpress/react-components/lib/colors';

export const negative10TopMargin = css`
  margin-top: -10px;
`;

export const flexRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const infoIcon = css`
  margin-left: 10px;
  cursor: pointer;
`;

export const infoText = css`
  border-left: 3px solid ${info.base};
  padding: 2px 10px;
  color: ${slate};
`;

export const anchorButton = css`
  background: transparent;
  border: none;
  font-weight: 300;
  color: ${ocean.base};
  margin: 10px 0 10px;
  &:hover {
    text-decoration: underline;
  }
`;

export const anchorButtonNoMargin = css`
  background: transparent;
  padding: 0px;
  border: none;
  font-weight: 300;
  color: ${ocean.base};
  &:hover {
    text-decoration: underline;
  }
`;

export const anchorButtonNoUnderline = css`
  background: transparent;
  border: none;
  font-weight: 300;
  color: ${ocean.base};
  margin: 10px 0 10px;
  &:hover {
    font-weight: 500;
  }
`;

export const anchorButtonInLineText = css`
  background: transparent;
  border: none;
  font-weight: 300;
  color: ${ocean.base};
  margin: 0px;
  &:hover {
    text-decoration: underline;
  }
`;

export const accordionOverflowVisible = css`
  & .accordion > div:not(.accordion-header) {
    overflow: visible !important;
  }

  & .accordion > div:not(.accordion-header) > div {
    overflow: visible !important;
  }
`;
