import React, { useState, useEffect } from 'react';
import { css } from 'emotion';

import SeeAll from './SeeAll';
import Item from './Item';
import SelectAllTopItem from './SelectAllTopItem';

const dropdownDiv = css`
  border-top: 1px solid #e5e8ec;
  display: block;
  transition: all 0.2s ease-in-out;
  box-sizing: content-box;
  position: absolute;
  z-index: 10;
  background-color: #fff;
  border: solid 1px #dfdfdf;
  box-shadow: 1px 1px 2px #cfcfcf;
  max-width: 698px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -16px;
`;

const MAX_SIZE = 5;
const Dropdown = ({
  standardsHaveAdoptions, // needed for dropdown component for whether you can select in addition to a standard
  searchResults,
  selectedProducts,
  addSelectedProducts,
  removeSelectedProducts,
  openDrawerFromDropdown,
  closeDropdown,
}) => {
  const [selectedChecks, setSelectedChecks] = useState({});
  const [topSlice] = useState(searchResults.length > MAX_SIZE ? MAX_SIZE : searchResults.length);

  useEffect(() => {
    const checks = {};
    searchResults.forEach((result) => {
      checks[result.productId] = false;
    });

    selectedProducts.forEach((product) => {
      checks[product.productId] = true;
    });
    setSelectedChecks(checks);
  }, [searchResults, selectedProducts]);

  const setProductsCheck = (products, isChecked) => {
    const copySelected = { ...selectedChecks };
    products.forEach((product) => {
      copySelected[product.productId] = isChecked;
    });

    if (isChecked) {
      addSelectedProducts(products);
    } else {
      removeSelectedProducts(products);
    }
    setSelectedChecks(copySelected);
  };

  return (
    <div className={dropdownDiv}>
      <SelectAllTopItem searchResults={searchResults} setProductsCheck={setProductsCheck} />
      {searchResults.slice(0, topSlice).map((x) => {
        return (
          <Item
            key={`dropdown-${x.productId}`}
            product={x}
            isSelected={selectedChecks[x.productId]}
            setProductsCheck={setProductsCheck}
          />
        );
      })}
      <SeeAll
        openAdvancedSearchDrawer={openDrawerFromDropdown}
        searchResults={searchResults}
        closeDropdown={closeDropdown}
        MAX_SIZE={MAX_SIZE}
      />
    </div>
  );
};

export default Dropdown;
