import { defineMessages } from 'react-intl';

export default defineMessages({
  errorValidatingInput: {
    id: 'ValidationResultDisplay.errorValidatingInput',
    defaultMessage: 'Error(s) occurred validating input: ',
  },
  warningValidatingInput: {
    id: 'ValidationResultDisplay.warningValidatingInput',
    defaultMessage: 'Warning(s) occurred validating input: ',
  },
  numberInvalidFormat: {
    id: 'numberValidation.numberInvalidFormat',
    defaultMessage: 'The value is not a number.',
  },
  numberInvalidPrecision: {
    id: 'numberValidation.numberInvalidPrecision',
    defaultMessage: 'The value exceeds maximum precision.  Maximum decimal places is 8.',
  },
  numberInvalidValue: {
    id: 'numberValidation.numberInvalidValue',
    defaultMessage: 'The specified value is not within the set of valid values.',
  },
  formatError: {
    id: 'rangeValidation.formatError',
    defaultMessage:
      "<intro>Please use one of the supported formats:</intro><list><listitem>'{number}' (eg, 1 for exactly 1)</listitem><listitem>'{min}'-'{max}' (eg, 1-2 for all numbers between 1 to 2)</listitem><listitem>'{min}'-'{max}'#'{increment}' (eg, 1-10#1 for all numbers between 1 and 10 in increments of 1)</listitem><listitem>'{min}'+ or '{min}'-infinity (eg, 2+ or 2-infinity for all numbers from 2 to infinity)</listitem><listitem>'{min}'+#'{increment}' (eg, 3+#2 for all numbers from 3 to infinity in increments of 2)</listitem></list>",
  },
  invalidMinPrecision: {
    id: 'rangeValidation.invalidMinPrecision',
    defaultMessage: 'Minimum exceeds maximum precision.  Maximum decimal places is {precision}.',
  },
  invalidMaxPrecision: {
    id: 'rangeValidation.invalidMaxPrecision',
    defaultMessage: 'Maximum exceeds maximum precision.  Maximum decimal places is {precision}.',
  },
  invalidValuePrecision: {
    id: 'rangeValidation.invalidValuePrecision',
    defaultMessage: 'Single numeric value exceeds maximum precision. Maximum decimal places is {precision}.',
  },
  minGtMax: {
    id: 'rangeValidation.minGtMax',
    defaultMessage: 'Maximum should be greater than or equal to Minimum.',
  },
  invalidIncrementPrecision: {
    id: `rangeValidation.invalidIncrementPrecision`,
    defaultMessage: 'Increment exceeds maximum precision.  Maximum decimal places is {precision}.',
  },
  rangeIsDuplicate: {
    id: 'rangeValidation.rangeIsDuplicate',
    defaultMessage: 'Range has duplicates.',
  },
  maxNotOnIncrement: {
    id: 'rangeValidation.maxNotOnIncrement',
    defaultMessage:
      'The maximum value given does not match the increment. The maximum value on the increment would be {max}.',
  },
  rangeInvalidValue: {
    id: 'rangeValidation.rangeInvalidValue',
    defaultMessage: 'The specified range is not within the set of valid values',
  },
  colorInvalid: {
    id: 'hexcodeValidation.colorInvalid',
    defaultMessage: 'The specified string is not a valid Hex Code',
  },
});
