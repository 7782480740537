import { defineMessages } from 'react-intl';

export default defineMessages({
  search: {
    id: 'productLinker.search',
    defaultMessage: 'Search',
  },
  searchParameters: {
    id: 'productLinker.searchParameters',
    defaultMessage: 'Search Parameters',
  },
  fetchingCatalogs: {
    id: 'productLinker.fetchingCatalogs',
    defaultMessage: 'Fetching Catalogs...',
  },
  noCatalogsFound: {
    id: 'productLinker.noCatalogsFound',
    defaultMessage: 'No Catalogs Found',
  },
  catalog: {
    id: 'productLinker.catalogs',
    defaultMessage: 'Catalog',
  },
});
